import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Social = () => {
    return (
        <div id="committee">
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header className="member-card-header">

                                <h1 className="member-title">Follow Us</h1>
                            </Card.Header>
                            <Card.Body > 
        
                            <MDBContainer>
                            <MDBRow>
                                <MDBCol size='md'>
                                <a href="https://www.facebook.com/groups/OneKennedyClub/" target="_blank">
                                <FontAwesomeIcon icon={faFacebook} size="3x" />
                                </a>
                                </MDBCol>

                                <MDBCol size='md'>
                                <a href="https://www.instagram.com/onekennedyclub" target="_blank">
                                <FontAwesomeIcon icon={faInstagram} size="3x" />
                                </a>
                                </MDBCol>
                            </MDBRow>
                            </MDBContainer>                           
                      
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container>
        </div>
    );
};

export default Social;