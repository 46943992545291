import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import React, { useState } from "react";
import styled from 'styled-components'

import { Button } from "react-bootstrap";

import { GoogleFormProvider, useGoogleForm } from 'react-google-forms-hooks'

import form from '../scripts/form.json';
import ShortAnswerInput from './ShortAnswerInput'
import DropdownInput from './DropdownInput'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { BellOutlined } from '@ant-design/icons';

const Form = styled.form`

`

const QuestionContainer = styled.div`

`

const QuestionLabel = styled.h5`

`

const QuestionHelp = styled.p`
  font-size: 0.8rem;
  color: #5c5c5c;
  margin-top: 0px;
`

const Questions = () => {
  return (
    <div>
      {form.fields.map((field) => {
        const { id } = field

        let questionInput = null
        switch (field.type) {
          case 'SHORT_ANSWER':
            questionInput = <ShortAnswerInput id={id} />
            break
          case 'DROPDOWN':
            questionInput = <DropdownInput id={id} />
            break
        }

        if (!questionInput) {
          return null
        }

        return (
          <QuestionContainer key={id}>
            <QuestionLabel>{field.label}</QuestionLabel>
            {questionInput}
            <QuestionHelp>{field.description}</QuestionHelp>
          </QuestionContainer>
        )
      })}
    </div>
  )
}



const Connect = (props) => {
  const email = "OneKennedyClub@gmail.com";
  const emailLink = `mailto:${email}`;

  const methods = useGoogleForm({ form })

  const onSubmit = async (data) => {
    console.log('>>> Here is the data', data)
    await methods.submitToGoogleForms(data)
    alert('Form submitted with success!')
  }

  console.log('>>> Here are the errors!!!', methods.formState.errors)

  return (
    <div id="connect" className="text-center">
      <Container>
        <Row>
          <Col>
            <Card>
              <Card.Header className="member-card-header text-center">
                <h1 className="member-title">Connect</h1>
              </Card.Header>
              <Card.Body>
              <div id="maillist" className="text-center">                  
                  <GoogleFormProvider {...methods}>
                  <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    {form.title && (
                      <>
                        <h2>{form.title}</h2>
                        {form.description && (
                          <p> {form.description}  </p>
                        )}
                      </> 
                    )}
                    <Questions />
                    <Button type='submit' icon={<BellOutlined />}>
                       SUBSCRIBE 
                    </Button>
                  </Form>
                </GoogleFormProvider>

                <br />
                </div>
                <br />
                <h2>Contact Us</h2>
                <h5>Email us:</h5>
                <p>For events, joining our mailing list and joining the 1KC Community WhatsApp Group, please email <a href={emailLink}>{email}</a> . For all other enquiries, please email <a href={emailLink}>{email}</a> .</p>

                <h5>Write to us:</h5>
                <p>FAO: The Secretary <br/>
                One Kennedy Club <br/>
                1 Kennedy Road <br />
                Mid-Levels <br />
                </p>
                <br />
      
                <h2>
                Interested in becoming a Freemason?
                </h2>
                <p>Please visit <a href="https://www.zetlandhall.com/what-is-freemasonry" target="_blank">Zetland Hall</a>’s Webpage to find out more about being a Freemason in Hong Kong. Please note that the One Kennedy Club operates under the English Constitution as part of the District Grand Lodge of Hong Kong and the Far East.</p>
              
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>

  );
};
export default Connect;

