import React from 'react'
import { useDropdownInput } from 'react-google-forms-hooks'
import styled from 'styled-components'

const StyledSelect = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none;
  background-image: url("data:image/svg+xml,..."); // Add a custom dropdown arrow
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 12px auto;
`

export default function DropdownInput({ id }) {
  const { register, options } = useDropdownInput(id)

  return (
    <div>
      <StyledSelect {...register()}>
        <option value=''>Select option</option>
        {options.map((o) => (
            <option key={o.label} value={o.label}>
              {o.label}
            </option>
        ))}
      </StyledSelect>
    </div>
  )
}