import './App.scss';
import Navbar from './components/navbar';
import About from './components/about';
import Events from './components/event';
import Committee from './components/committee';
import Contact from './components/connect';

import Link from './components/link';

import Social from './components/social';


import Footer from './components/footer';


import "swiper/css/bundle";


function App() {
  return (
    <div className="App">
      <Navbar/>
      <About/>
      <br/>
      <Events/>
      <br/>
      <Committee />
      <br/>
      <Contact/>
      <br/>
      <Social/>
      <br/>
      <Link/>
      
      <Footer/>

    </div>
  );
}

export default App;
