import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";


import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';


import committeeImage from "../assets/committee.jpeg";
import committeeImage2 from "../assets/committee2.jpeg";
import committeeImage3 from "../assets/committee3.jpeg";


const Committee = props => {
  const email = "OneKennedyClub@gmail.com";
  const emailLink = `mailto:${email}`;

  return (
    <div id="committee">
      <Container>
        <Row>
          <Col>
            <Card>
              <Card.Header className="member-card-header">
                <h1 className="member-title">The One Kennedy Club Committee </h1>
              </Card.Header>
              <Card.Body>

<div>
  <p>
    <MDBCarousel showControls interval={3000}>
      <MDBCarouselItem itemId={1}>
        <img src={committeeImage} className='d-block w-100' alt='...' />
      </MDBCarouselItem>
      <MDBCarouselItem itemId={2}>
        <img src={committeeImage2} className='d-block w-100' alt='...' />
      </MDBCarouselItem>
      <MDBCarouselItem itemId={2}>
        <img src={committeeImage3} className='d-block w-100' alt='...' />
      </MDBCarouselItem>
    </MDBCarousel>
  </p>
</div>


               <div style={{textAlign: "center"}}>
    <p>As of 2024, The One Kennedy Club Committee is comprised of 18 active young Brethren from a mixture of English Lodges across Hong Kong, headed by our Chairman, Deputy Chairman and Secretary.</p>
    {/* <p>
        <i className="fa fa-quote-left member-qutoe-icon"></i><span>  </span>
        The Club’s primary role is to provide social events for newer and younger Freemasons to meet each other and encourage their involvement with Masonry in general and Lodge activities in particular.{' '}
        <span>  </span><i className="fa fa-quote-right member-qutoe-icon"></i>
    </p> */}
    <br/>
    <h3>Interested in helping the One Kennedy Club Committee?</h3>
    <p>
        We are always looking for young Brethren (46 years old and under) to help and support us in bringing all Brethren together across Lodges to create opportunities for camaraderie, Masonic learning, as well as events where Gentleman guests can be introduced to our wonderful Craft. If you are interested in getting involved with the 1KC Committee and our events, please contact us at {" "}
        <a href={emailLink}>{email}</a>  - we would love to hear from you.
    </p>
</div>
              
              
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Committee;