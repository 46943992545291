import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Card from "react-bootstrap/Card";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";



// import required modules
import { EffectCoverflow, Pagination } from "swiper";


import NewEvent from "../assets/202412.jpeg";
import slide19 from "../assets/20240823PastEvent.jpeg";
import slide18 from "../assets/20240622PastEvent.jpeg";
import slide1 from "../assets/20240424PastEvent.jpeg";
import slide2 from "../assets/202402PastEvent.png";
import slide3 from "../assets/20231128PastEvent.jpg";
import slide4 from "../assets/20230825PastEvent.png";
import slide5 from "../assets/20230719PastEvent.jpg";
import slide6 from "../assets/20230510PastEvent.jpg";
import slide7 from "../assets/20230329PastEvent.jpg";
import slide8 from "../assets/20221129PastEvent.jpg";
import slide9 from "../assets/20211116PastEvent.jpg";
import slide10 from "../assets/20210707PastEvent.png";
import slide11 from "../assets/20210609PastEvent.png";
import slide12 from "../assets/20200702PastEvent.jpeg";
import slide13 from "../assets/20190226PastEvent.png";
import slide14 from "../assets/20181028PastEvent.jpg";
import slide15 from "../assets/20181003PastEvent.png";
import slide16 from "../assets/20180626PastEvent.png";
import slide17 from "../assets/20180328PastEvent.png";


import {
  useWindowWidth
} from '@react-hook/window-size'

const Event = props => {
  const onlyWidth = useWindowWidth()
  const [showMoreSliders, setShowMoreSliders] = useState(false);

  useEffect(() => {
    if (onlyWidth >= 768) {
      setShowMoreSliders(true);
    } else {
      setShowMoreSliders(false); 
    }
  }, [onlyWidth])

  return (
    <div id="event">
      <Container>
        <Row>
          <Col>
            <Card>
              <Card.Header className="member-card-header">
                <h1 className="member-title">Events</h1>
              </Card.Header>
              <Card.Body>
              <h2>Upcoming Event</h2>
              <br></br>
              <p style={{textAlign: "center"}}>   

<div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
  <div style={{width: "282.5px", height: "400px", perspective: "1000px"}}>
    <div style={{position: "relative", width: "100%", height: "100%", textAlign: "center", transition: "transform 0.8s", transformStyle: "preserve-3d", cursor: "pointer"}}
         onMouseEnter={(e) => e.currentTarget.style.transform = 'rotateY(180deg)'}
         onMouseLeave={(e) => e.currentTarget.style.transform = ''}>
      <div style={{position: "absolute", width: "100%", height: "100%", backfaceVisibility: "hidden", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <img src={NewEvent} alt="event img" style={{width: "100%", height: "100%"}}/>
      </div>
      <div style={{position: "absolute", width: "100%", height: "100%", backfaceVisibility: "hidden", transform: "rotateY(180deg)", backgroundColor: "#f8f8f8", display: "flex", justifyContent: "center", alignItems: "center"}}>

          <h2>Pls contact any 1KC committee members to sign up for this event</h2>      
      </div>
    </div>
  </div>
</div>


  Please watch this space for upcoming events. If you have not done so already, please join our <a href="#maillist">mailing list</a> below.
</p>

{/* ======== previous events ========== */}

             <h2>Previous Events</h2>

              <Swiper 
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          spaceBetween={30}
          slidesPerView={showMoreSliders? 3 : 1}
          autoplay={true}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination]}
          className="mySwiper2"
        >
          <SwiperSlide>
            <img src={slide19} alt="event img"/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide18} alt="event img"/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide1} alt="event img"/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide2} alt="event img"/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide3} alt="event img"/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide4} alt="event img"/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide5} alt="event img"/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide6} alt="event img"/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide7} alt="event img"/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide8} alt="event img"/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide9} alt="event img"/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide10} alt="event img"/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide11} alt="event img"/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide12} alt="event img"/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide13} alt="event img"/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide14} alt="event img"/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide15} alt="event img"/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide16} alt="event img"/>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide17} alt="event img"/>
          </SwiperSlide>

        </Swiper>
              
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>


  )
}

export default Event;