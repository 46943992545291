import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Link = () => {
    return (
        <div id="committee">
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header className="member-card-header">

                                <h1 className="member-title">Links</h1>
                            </Card.Header>
                            <Card.Body > 
                                <a href="https://www.zetlandhall.com/" target="_blank">Zetland Hall</a>
                                <br />
                                The home of Freemasonry in Hong Kong.
                                <br /><br />
                                <a href="https://www.ugle.org.uk/" target="_blank">The United Grand Lodge of England</a>
                                <br />
                                The United Grand Lodge of England’s (UGLE) Homepage.
                                <br /><br />
                                <a href="https://www.ugle.org.uk/discover-freemasonry/freemasonry-today-magazine" target="_blank">Freemasonry Today</a>
                                <br />
                                Freemasonry Today (FMT) is UGLE’s quarterly print magazine, including UGLE communications, stories across UK Provinces and Districts, and much more.
                                <br /><br />
                                <a href="https://solomon.ugle.org.uk/" target="_blank">Solomon</a>
                                <br />
                                A virtual learning environment to support Masonic Learning.
                                <br /><br />
                                <a href="https://podcasts.apple.com/gb/podcast/craftcast-the-freemasons-podcast/id1652652045" target="_blank">Craftcast: The Freemasons Podcast</a>
                                <br />
                                A Masonic podcast curated by the United Grand Lodge of England.
                                <br /><br />

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container>
        </div>
    );
};

export default Link;