import { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../assets/logo512.png";

const CustomNavbar = props => {
  const  [ activeItem, setActiveItem ] = useState("about"); 

  const handleSelectAbout = () => {
    setActiveItem("about");
  }

  const handleSelectEvent = () => {
    setActiveItem("event");
  }

  const handleSelectMember = () => {
    setActiveItem("committee");
  }

  const handleSelectContact = () => {
    setActiveItem("connect");
  }

  return (
    <div>
      <Navbar expand="md" fixed="top" className="navbar" variant="light" bg="white" collapseOnSelect>
        <Container>
          <Navbar.Brand href="#">
            <img
              className="nav-logo"
              src={logo}
              width="86"
              height="80"
              alt="1KC logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="toggle-button"/>
          <Navbar.Collapse 
            id="basic-navbar-nav"
            className="navbar-body"
            > 
            <Nav className="col-md-3">
                <div className={activeItem === "about" ? "nav-items-border-active" : "nav-items-border"}>
                  <Nav.Link href="#about" className="nav-items nav-items-left" onClick={handleSelectAbout} active={activeItem === "about"}>About</Nav.Link>   
                </div>
              </Nav>
              <Nav className="col-md-3">
                <div className={activeItem === "event" ? "nav-items-border-active" : "nav-items-border"}>
                  <Nav.Link href="#event" className="nav-items nav-items-left" onClick={handleSelectEvent} active={activeItem === "events"}>Events</Nav.Link>
                </div>
              </Nav>
              <Nav className="col-md-3 justify-content-end">
                <div className={activeItem === "committee" ? "nav-items-border-active" : "nav-items-border"}>
                  <Nav.Link href="#committee" className="nav-items nav-items-right" onClick={handleSelectMember} active={activeItem === "committee"}>Committee</Nav.Link>
                </div>
              </Nav>
               <Nav className="col-md-3 justify-content-end">
                <div className={activeItem === "Connect" ? "nav-items-border-active" : "nav-items-border"}>
                  <Nav.Link href="#connect" className="nav-items nav-items-right" onClick={handleSelectContact} active={activeItem === "connect"}>Connect</Nav.Link>
                </div>
              </Nav> 
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default CustomNavbar;