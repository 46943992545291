import React from 'react';

const Footer = () => {
    return (
        <footer className="footer" style={{ marginBottom: 0 }}>
            <p>©2024 One Kennedy Club All Rights Reserved</p>
        </footer>
    );
};

export default Footer;