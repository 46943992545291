import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import group from "../assets/group-photo.jpeg";
import Aos from "aos";
import "aos/dist/aos.css";

const About = props => {

  useEffect(() => {
    Aos.init({ duration: 50 })
  }, [])

  return (
    <div id="about">
      <Container>
      <div data-aos="fade-up" className="logo-ani mx-auto logo-color">
      <Row className="h-100 justify-content-start">
        <Col>
        <svg id="_x31_kc" style={{"enableBackground":"new 0 0 172.3 172.3"}} version="1.1" viewBox="0 0 172.3 172.3" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" xmlSpace="preserve">
          <g id="text">
            <path className="st0" d="M145.5,131.3l2.5-3.3l0.3,0.2c0.5,0.4,1,0.9,1.4,1.4c0.3,0.5,0.6,1,0.7,1.6c0.3,1.3,0,2.7-0.9,3.7&#xA;&#xA;&#x9;&#x9;c-0.8,1.1-2,1.8-3.3,2c-2.8,0.4-5.4-1.6-5.8-4.4c-0.2-1.4,0.2-2.7,1-3.8c0.4-0.6,1-1.1,1.7-1.4c0.7-0.4,1.5-0.6,2.4-0.6l0.2,1.5&#xA;&#xA;&#x9;&#x9;c-1.2-0.1-2.3,0.5-3.1,1.4c-0.6,0.8-0.9,1.8-0.7,2.8c0.1,1,0.7,1.9,1.5,2.5c1.6,1.2,3.9,0.9,5.1-0.6c0.4-0.6,0.6-1.3,0.6-2&#xA;&#xA;&#x9;&#x9;c0-0.7-0.3-1.4-0.9-1.9l-1.5,2"/>
            <path className="st0" d="M111.4,157c-0.5-1.2-0.5-2.7,0-3.9c0.5-1.3,1.5-2.3,2.8-2.9c2.6-1.1,5.6,0,6.8,2.6c0.6,1.2,0.6,2.7,0.1,3.9&#xA;&#xA;&#x9;&#x9;c-1,2.5-3.9,3.9-6.5,3C113.1,159.4,112,158.3,111.4,157 M112.8,156.4c0.4,0.9,1.1,1.7,2.1,2c1.9,0.7,4-0.2,4.7-2.1&#xA;&#xA;&#x9;&#x9;c0.7-1.9-0.2-4.1-2.1-4.9c-0.9-0.4-1.9-0.4-2.8,0.1c-0.9,0.4-1.6,1.1-2,2C112.3,154.4,112.4,155.4,112.8,156.4"/>
            <path className="st0" d="M72.1,160.1l4,0.8l-0.1,0.3c-0.1,0.7-0.3,1.3-0.6,1.9c-0.3,0.5-0.7,0.9-1.1,1.3c-1.1,0.8-2.4,1.2-3.7,0.9&#xA;&#xA;&#x9;&#x9;c-1.3-0.2-2.5-1-3.2-2.1c-1.6-2.4-0.9-5.6,1.5-7.2c1.8-1.2,4.1-1.1,5.8,0.1c0.6,0.5,1.2,1.1,1.6,1.9l-1.2,0.8&#xA;&#xA;&#x9;&#x9;c-0.5-1.1-1.4-1.9-2.6-2.1c-1-0.2-2,0-2.8,0.6c-0.8,0.6-1.4,1.4-1.5,2.4c-0.4,2,0.9,3.9,2.8,4.3c0.7,0.1,1.5,0,2.1-0.4&#xA;&#xA;&#x9;&#x9;c0.6-0.3,1.1-0.9,1.3-1.6l-2.4-0.5"/>
            <path className="st0" d="M33.7,141.2c0.8-1.1,2.1-1.7,3.5-1.8c1.4-0.2,2.8,0.3,3.8,1.2c2.2,1.8,2.5,5,0.7,7.2c-0.8,1.1-2.1,1.7-3.5,1.8&#xA;&#xA;&#x9;&#x9;c-2.7,0.2-5.2-1.7-5.6-4.4C32.4,143.8,32.8,142.4,33.7,141.2 M34.9,142.3c-1.3,1.6-1.1,3.9,0.5,5.2c0.7,0.6,1.7,0.9,2.7,0.8&#xA;&#xA;&#x9;&#x9;c2-0.2,3.5-2.1,3.4-4.1c-0.1-1-0.6-1.9-1.3-2.5C38.5,140.4,36.2,140.6,34.9,142.3C34.9,142.2,34.9,142.2,34.9,142.3"/>
            <path className="st0" d="M13.7,106.4c-0.2-0.5-0.2-1,0.1-1.4c0.5-1,1.6-1.4,2.6-1c0.5,0.2,0.8,0.6,1,1.1c0.2,0.5,0.2,1-0.1,1.5&#xA;&#xA;&#x9;&#x9;c-0.2,0.5-0.6,0.8-1.1,1C15.2,107.9,14,107.4,13.7,106.4C13.7,106.4,13.7,106.4,13.7,106.4"/>
            <path className="st0" d="M160.5,106.3c-0.1,0.5-0.5,0.9-0.9,1.1c-0.9,0.5-2.1,0.2-2.6-0.8c-0.3-0.4-0.3-1-0.2-1.5&#xA;&#xA;&#x9;&#x9;c0.1-0.5,0.5-0.9,0.9-1.1c0.5-0.3,1-0.3,1.5-0.2C160.2,104.2,160.8,105.2,160.5,106.3C160.5,106.3,160.5,106.3,160.5,106.3"/>
            <path className="st0" d="M156.5,78.7l9.2-0.6l0.1,1.4c0.1,0.5,0.1,1,0,1.5c-0.1,0.4-0.2,0.7-0.4,1c-0.2,0.3-0.5,0.5-0.8,0.7&#xA;&#xA;&#x9;&#x9;c-0.3,0.2-0.7,0.3-1.1,0.3c-0.6,0.1-1.3-0.2-1.7-0.6c-0.1,0.5-0.4,0.9-0.8,1.2c-1,0.8-2.4,0.7-3.3-0.1c-0.3-0.3-0.6-0.6-0.7-1&#xA;&#xA;&#x9;&#x9;c-0.2-0.5-0.3-1-0.3-1.6 M158,80l0.1,0.9c0,0.5,0.2,1,0.5,1.4c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.8-0.2,1-0.5c0.3-0.5,0.4-1,0.3-1.6&#xA;&#xA;&#x9;&#x9;v-0.7L158,80z M162,79.7v0.4c0,0.4,0.1,0.8,0.4,1.1c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.2,1-0.4c0.2-0.3,0.3-0.7,0.3-1.1v-0.5&#xA;&#xA;&#x9;&#x9;L162,79.7z"/>
            <path className="st0" d="M162.6,62.4l-5.4,1.5c-0.6,0.1-1.1,0.4-1.6,0.9c-0.7,0.9-0.5,2.2,0.5,2.9c0.1,0.1,0.3,0.2,0.4,0.2&#xA;&#xA;&#x9;&#x9;c0.6,0.1,1.2,0.1,1.8-0.1l5.4-1.5l0.4,1.3l-5.8,1.6c-0.9,0.3-1.8,0.2-2.6-0.2c-1.7-0.9-2.3-3.1-1.4-4.8c0,0,0,0,0-0.1&#xA;&#xA;&#x9;&#x9;c0.5-0.8,1.2-1.3,2.1-1.5l5.8-1.6"/>
            <path className="st0" d="M151.5,40.7l-1.3,1c0.1-0.9-0.2-1.9-0.7-2.6c-0.5-0.7-1.4-1.2-2.3-1.3c-0.9-0.1-1.9,0.2-2.6,0.7&#xA;&#xA;&#x9;&#x9;c-0.7,0.5-1.2,1.4-1.4,2.3c-0.1,0.9,0.1,1.8,0.7,2.5c0.3,0.4,0.6,0.6,1,0.8c0.2,0.1,0.4,0.2,0.6,0.3c0.3,0.1,0.5,0.2,0.8,0.2&#xA;&#xA;&#x9;&#x9;l-1.3,1c-0.8-0.3-1.5-0.9-2.1-1.6c-1.6-2.1-1.2-5.1,0.8-6.7c0,0,0,0,0,0c0.9-0.7,2.1-1.1,3.2-1c1.4,0.1,2.8,0.8,3.6,1.9&#xA;&#xA;&#x9;&#x9;C151.1,39,151.4,39.8,151.5,40.7"/>
            <path className="st0" d="M108.7,19l3.3-8.6l1.8,0.7c0.7,0.2,1.4,0.6,2,1.1c0.5,0.4,0.9,0.9,1.1,1.4c0.6,1.2,0.6,2.5,0.1,3.7&#xA;&#xA;&#x9;&#x9;c-0.7,1.9-2.5,3.1-4.4,3c-0.7-0.1-1.4-0.3-2.1-0.5 M110.5,18.3l0.6,0.2c0.5,0.2,1,0.3,1.5,0.4c1.4,0.1,2.7-0.8,3.2-2.1&#xA;&#xA;&#x9;&#x9;c0.4-0.9,0.4-1.8-0.1-2.7c-0.5-0.8-1.3-1.5-2.2-1.8l-0.6-0.2L110.5,18.3z"/>
            <path className="st0" d="M10.6,84.1c-1.3-0.1-2.4-0.7-3.2-1.7c-0.8-1-1.2-2.3-1.1-3.6C6.4,77.6,7,76.4,8,75.6c1-0.8,2.2-1.2,3.5-1.1&#xA;&#xA;&#x9;&#x9;c1.3,0.1,2.5,0.7,3.2,1.7c1.6,2,1.4,4.9-0.4,6.7C13.3,83.8,12,84.3,10.6,84.1 M10.7,82.7c1.9,0.2,3.6-1.2,3.8-3.1&#xA;&#xA;&#x9;&#x9;c0.1-0.9-0.2-1.8-0.8-2.6c-0.6-0.7-1.5-1.2-2.4-1.2c-0.9-0.1-1.8,0.2-2.6,0.8c-1.5,1.2-1.7,3.4-0.4,4.9&#xA;&#xA;&#x9;&#x9;C8.9,82.2,9.8,82.7,10.7,82.7"/>
            <polyline className="st0" points="131.9,151.9 125.4,143.6 135.7,145.1 131.4,139.6 132.6,138.7 139.1,147 128.8,145.5 133.1,151 &#x9;"/>
            <polyline className="st0" points="98,159.4 101.1,154.8 103,154.4 99.5,159.6 104.9,164.1 103,164.5 98.6,160.9 98.4,161.2 99.2,165.2 &#xA;&#xA;&#x9;&#x9;97.7,165.5 95.8,155.8 97.3,155.5 &#x9;"/>
            <polyline className="st0" points="47.6,157.2 52,147.6 55.5,157.4 58.3,151.2 59.7,151.8 55.3,161.4 51.9,151.5 49,157.8 &#x9;"/>
            <polyline className="st0" points="24.9,129.1 27.4,132.5 30.5,130.2 31.4,131.4 23.4,137.3 22.5,136.1 26.3,133.3 23.8,129.9 20,132.6 &#xA;&#xA;&#x9;&#x9;19.1,131.4 27.1,125.6 28,126.8 &#x9;"/>
            <polyline className="st0" points="157.6,50.1 150.5,53.7 151.7,56.1 150.5,56.7 148.7,53 157,48.9 &#x9;"/>
            <polyline className="st0" points="125.8,22.8 126.1,16.8 127.5,17.6 127.3,21.9 131.1,19.9 132.4,20.8 127,23.6 124.9,27 123.7,26.2 &#x9;&#xA;&#xA;&#x9;&#x9;"/>
            <polyline className="st0" points="102.1,9 98.4,8.4 98.1,10.6 101.6,11.2 101.4,12.5 97.8,11.9 97.3,15 101,15.6 100.8,16.9 95.7,16 &#xA;&#xA;&#x9;&#x9;97.3,6.9 102.3,7.8 &#x9;"/>
            <polyline className="st0" points="79,15.7 78.6,5.8 85.7,12.6 85.4,6.1 86.8,6.1 87.2,15.9 80.2,9.1 80.4,15.6 &#x9;"/>
            <polyline className="st0" points="62.8,19.3 60.1,9.7 68.5,14.7 66.8,8.5 68.1,8.1 70.8,17.6 62.3,12.6 64.1,18.9 &#x9;"/>
            <polyline className="st0" points="51.5,15.4 48.2,17.2 49.3,19.1 52.5,17.5 53.1,18.6 49.9,20.3 51.4,23 54.7,21.3 55.3,22.5 &#xA;&#xA;&#x9;&#x9;50.8,24.9 46.4,16.7 50.9,14.3 &#x9;"/>
            <polyline className="st0" points="36.6,28.1 37,23 38.5,21.8 37.9,27.7 44.3,29 42.9,30.2 37.7,29.1 37.7,29.5 40.1,32.5 39,33.3 &#xA;&#xA;&#x9;&#x9;33.1,26.2 34.2,25.3 &#x9;"/>
            <polyline className="st0" points="20.2,43.2 18.3,46.3 20.2,47.5 22,44.4 23.2,45.1 21.3,48.2 24,49.8 25.9,46.6 27,47.3 24.4,51.7 &#xA;&#xA;&#x9;&#x9;16.4,46.9 19.1,42.5 &#x9;"/>
            <polyline className="st0" points="17.9,67.1 8.6,63.8 17.4,59.7 11.3,57.7 11.8,56.3 21.1,59.5 12.2,63.6 18.4,65.7 &#x9;"/>
          </g>
          <g className="st1" id="small-circle">
            <path className="st2" d="M86.2,19.2c-37,0-66.9,30-66.9,66.9s30,66.9,66.9,66.9s66.9-30,66.9-66.9l0,0C153.1,49.2,123.1,19.2,86.2,19.2&#xA;&#xA;&#x9;&#x9; M86.2,150.6c-35.6,0-64.5-28.9-64.5-64.5c0-35.6,28.9-64.5,64.5-64.5c35.6,0,64.5,28.9,64.5,64.5&#xA;&#xA;&#x9;&#x9;C150.6,121.8,121.8,150.6,86.2,150.6C86.2,150.6,86.2,150.6,86.2,150.6"/>
          </g>
          <g id="large-circle">
            <path className="st0" d="M86.2,0C38.6,0,0,38.6,0,86.2s38.6,86.2,86.2,86.2s86.2-38.6,86.2-86.2S133.7,0,86.2,0C86.2,0,86.2,0,86.2,0&#xA;&#xA;&#x9;&#x9; M86.2,169.1c-45.8,0-83-37.2-83-83s37.2-83,83-83s83,37.2,83,83v0C169.1,132,132,169.1,86.2,169.1"/>
          </g>
          <g id="logo">
            <path className="st3" d="M86.2,31.4L94.7,46c9.4,16.2,18.8,32.3,28.3,48.5c0.9,1.6,1.2,3.5,0.8,5.3L86.2,47L48.6,99.8&#xA;&#xA;&#x9;&#x9;c-0.2-2-0.2-3.5,0.7-5c12.1-20.7,24.1-41.4,36.2-62.2C85.6,32.3,85.8,32,86.2,31.4"/>
            <path className="st3" d="M123.2,104l-37,36.9l-37-37.1c2.2-3.8,8.4-6,13.5-4.7c3.2,0.8,5.5,3,7.8,5.2c5,5,10,10.1,15,15.1&#xA;&#xA;&#x9;&#x9;c0.2,0.2,0.4,0.3,0.8,0.6c2.8-2.9,5.7-5.8,8.5-8.7c3.1-3.1,6.2-6.3,9.3-9.3c5.2-4.8,13.3-4.5,18.1,0.8&#xA;&#xA;&#x9;&#x9;C122.5,103.3,122.8,103.6,123.2,104"/>
          </g>
        </svg>
        </Col>
      </Row>
      </div>
      <div data-aos="fade-up" className="mx-auto">
      <Row>
        <Col className="about-box about-title my-auto">
          <h1>One Kennedy Club</h1>
          <p>The One Kennedy Club (1KC) annually organises a wide range of social and educational events, enabling young and newer Freemasonry members to connect and engage more actively within the community across all English Lodges in Hong Kong. In the past few years, 1KC has organised various social gatherings such as drinks socials, taster evenings, dinners, as well as Masonic lectures, meetings and Lodge visits. For upcoming events, please see below.</p>
          <br />
          <h2>Our History:</h2>  
          <p>1KC was established in 2018 and is part of the New and Young Masons Club initiative from the United Grand Lodge of England (UGLE), taking its roots from London's Connaught Club. 1KC was founded to give New and Young Freemasons in Hong Kong a means to meet and socialise with like-minded people, new to the Craft, whilst building camaraderie within the fraternity, bridging Hong Kong's many English Lodges. Our 'light blue' Club, guided by the principles of Freemasonry and the indelible "3 R's" - Recruitment, Retention, and Rejuvenation, as laid down by the Pro Grand Master, has continued to flourish since its inception into its 6th year. </p>
          <p>The One Kennedy Club is named after No. 1 Kennedy Road, where <a href="https://zetlandhall.com" target="_blank">Zetland Hall</a>  is located, the home of Freemasonry in Hong Kong.</p>
        </Col>
      </Row>
      </div>
      </Container>

          
    </div>
  )
}

export default About;